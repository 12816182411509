import React from 'react'
import MetaImage from '../../assets/images/story/miro/cover.png'
import CTASection from '../../components/CTASection'
import Layout from '../../components/Layout'
import QuoteBlock from '../../components/blocks/QuoteBlock'
import CaseStudyContainer from '../../components/story/CaseStudyContainer'
import MiroBody from '../../components/story/miro/Content'
import MiroHeader from '../../components/story/miro/Header'

const MiroPage = () => {
  return (
    <Layout
      title="Miro case study"
      description="Miro uses Swarmia to empower every level of the engineering organization with relevant information and insights: from high-level trends for the senior leadership all the way to actionable drill-downs for individual teams."
      metaImage={MetaImage}
    >
      <CaseStudyContainer>
        <MiroHeader />
        <QuoteBlock person="walter" />
        <MiroBody />
      </CaseStudyContainer>
      <CTASection>
        Swarmia is how the best teams get better. Get started today.
      </CTASection>
    </Layout>
  )
}
export default MiroPage
